import { observer } from "mobx-react";
import { AddAvitoAccount, MainLayout, PageTitle } from "../shared";
import React, { FC } from "react";
import { AvitoAccountList } from "../widgets";
import { Button, Flex } from "antd";
import { useAuthStore, useAvitoStore } from "../app/stores";
import BindTelegramAccountModal from "../shared/bindTelegramAccount";
import WidgetTelegramAccounts from "../widgets/TelegramAccounts";
import { useTgAccountsStore } from "../app/stores/tgAccounts.store";

const AvitoAccounts: FC<{ title: string }> = observer(({ title }) => {
  const { setOpennedPopup } = useAvitoStore();
  const { user } = useAuthStore()
  const { tgAccounts } = useTgAccountsStore()

  const [ tgAccountsModal, setTgAccountsModal ] = React.useState(false)
  const [ tgAccountsModalFirst, setTgAccountsModalFirst ] = React.useState(false)

  React.useEffect(() => {
    if(tgAccounts && !tgAccounts.length) {
      setTgAccountsModalFirst(true)
    }
  }, [tgAccounts])

  return (
    <MainLayout title={title}>
      <PageTitle>Аккаунты авито</PageTitle>
      <AddAvitoAccount />
      <WidgetTelegramAccounts opened={tgAccountsModal} tgAccountsModalFirst={tgAccountsModalFirst} onClose={() => {
        setTgAccountsModal(false)
        setTgAccountsModalFirst(false)
      }} />
      <Flex align={"center"} gap={10} className={`mt-4`}>
        <Button onClick={() => setOpennedPopup(true)}>Добавить аккаунт</Button>
        <Button type={"primary"} onClick={() => setTgAccountsModal(true)}>Привязать телеграм аккаунт</Button>
      </Flex>
      <AvitoAccountList />
    </MainLayout>
  );
});

export default AvitoAccounts;
