import { observer } from "mobx-react";
import { useAuthStore, useAvitoStore } from "../app/stores";
import React, { useEffect, useState } from "react";
import { getAvitoAccounts, refreshAvitoAccount } from "../shared";
import { Button, message, Modal, Popconfirm, Popover, Select, Switch, Table } from "antd";
import { useLocation } from "react-router-dom";
import Icon, { ApiOutlined, SettingOutlined, SyncOutlined } from "@ant-design/icons";
import { PacmanLoader } from "react-spinners";
import api from "../app/api";
import disableAvitoAccount from "../shared/api/disableAvitoAccount";
import enableAvitoAccount from "../shared/api/enableAvitoAccount";
import AvitoAccountInfoPopover from "./components/AvitoAccountInfoPopover";
import updateWebhooksProcess from "../shared/api/updateWebhooksProcess";
import { useAvitoPipelineStore } from "../app/stores/avitoPipelines.store";
import getAvitoPipelines from "../shared/api/getAvitoPipelines";

import updateAvitoAccountPipelineStatus from "../shared/api/updateAvitoAccountPipelineStatus";

const AvitoAccountList = observer(() => {
  const location = useLocation()

  const { setAccounts, accountsLoaded, accounts, setAccount } = useAvitoStore();
  const { pipelines, pipelinesLoaded, isPipelinesModalOpened, setIsPipelinesModalOpened } = useAvitoPipelineStore();
  const { selectedPipelineId, setSelectedPipelineId, selectedPipelineStatusId, setSelectedPipelineStatusId,setModalPipelineStatuses, modalPipelineStatuses} = useAvitoPipelineStore();

  const { user } = useAuthStore();
  const [selectedAvitoAccountId, setSelectedAvitoAccountId] = useState<number | null>(null);
  const [loadingSave, setLoadingSave] = useState(false);

  const showPipelineModal = (record: any) => {
    setSelectedAvitoAccountId(record.id);
    setSelectedPipelineId(record.avitoAccountAmoPipelineStatus?.amoPipeline?.id || null);
    setSelectedPipelineStatusId(record.avitoAccountAmoPipelineStatus?.amoPipelineStatus?.id || null);
    setIsPipelinesModalOpened(true);
  };

  useEffect(() => {
    if (loadingSave) {
      const timer = setTimeout(() => {
        handlePipelineModalOk();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [loadingSave]);

  const handlePipelineModalOk = async () => {
    
    if (!selectedAvitoAccountId) return;
    if (!selectedPipelineId)
      return message.error("Выберите воронку.");
    if (!selectedPipelineStatusId)
      return message.error("Выберите статус воронки.");

    setLoadingSave(true);
    message.info("Сохранение настроек...");

    const response = await updateAvitoAccountPipelineStatus({
      avitoAccountId: selectedAvitoAccountId,
      pipelineId: selectedPipelineId,
      statusId: selectedPipelineStatusId,
      token: user.token,
    });

    setLoadingSave(false);

    if (response?.status === 200) {
      message.info("Воронка успешно обновлена.");
      getAvitoAccounts({ token: user.token });
    } else {
      message.error("Ошибка при обновлении воронки. Попробуйте позже.");
    }

    setIsPipelinesModalOpened(false);
  };

  const handlePipelineModalCancel = () => {
    setIsPipelinesModalOpened(false);
  };

  useEffect(() => {
    if (!pipelinesLoaded) {
      getAvitoPipelines({ token: user.token });
    }
    if (!accountsLoaded) {
      getAvitoAccounts({ token: user.token });
    }
  }, [accountsLoaded, pipelinesLoaded, user.token]);

  useEffect(() => {
    setModalPipelineStatuses();
  }, [selectedPipelineId, setSelectedPipelineStatusId]);


  const webhooksProcessChange = async (id: number, enable: boolean) => {
    const response = await updateWebhooksProcess(id, enable,  user.token);
    if(response?.status === 201) {
      setAccount(response.data);
    } else {
      message.error("Что-то пошло не так. Обновите страницу и попробуйте снова!");
    }
  }
    
  const refreshAccount = async (id: number) => {
    const response = await refreshAvitoAccount(id);
    // const updatedAccounts = accounts
    //   .filter((e) => e.id !== id)
    //   .concat(response?.data);
    // setAccounts(updatedAccounts);

    if(response?.status === 201) message.success("Отправлен запрос на обновление. Аккаунт обновится в скором времени!")
    else {
      console.log(response)
      message.error("Что-то пошло не так. Обновите страницу и попробуйте снова!")
    }
  };

  const onConfirm = async (id: number) => {
    const response = await disableAvitoAccount(id, user.token)

    const account = response?.data
    if(response?.status !== 200 || !account) {
      console.log(response)
      return message.error("Что-то пошло не так. Обновите страницу и попробуйте снова!")
    }

    message.success("Аккаунт успешно удален");
    setAccounts(accounts.filter(acc => acc.id !== id));
  };

  const onEnable = async (id: number) => {
    const response = await enableAvitoAccount(id, user.token)

    if(response?.status === 400)return message.error("Аккаунт не валиден")
    if(response?.status === 403)return message.error("Пользователь не валиден")

    const account = response?.data
    if(response?.status !== 200 || !account) {
      console.log(response, account)
      return message.error("Что-то пошло не так. Обновите страницу и попробуйте снова!")
    }

    message.success("Аккаунт был успешно включен");
    setAccounts(accounts.map(acc => {
      if(acc.id === id) acc.active = account.active
      return acc
    }));
  }

  const [ loader, setLoader ] = React.useState(false)
  const [ isLogin, setIsLogin ] = React.useState<number | null>(null)

  async function loginAvitoAccount(id: number) {
    if(loader || isLogin)return

    setLoader(true)
    const result = await api.post(`amo/browser/login`, {
      avitoAccountId: id
    })

    setLoader(false)
    if(result.status === 200) {
      setIsLogin(id)
    }
    else {
      if(result.status === 404) {
        message.error("Аккаунт не найден! Обновите страницу");
      }
      else {
        message.error("Что-то пошло не так! Подробности в консоли разработчка");
        console.log(result)
      }
    }
  }
  async function loginAvitoAccountSubmit(id: number) {
    if(loader || !isLogin)return

    setLoader(true)
    const result = await api.post(`amo/browser/login/submit`, {
      avitoAccountId: id
    })

    setIsLogin(null)
    setLoader(false)

    if(result.status === 200) {
      message.success("Авторизация была пройдена.")
      setTimeout(() => {
        window.location = window.location
      }, 2000)
    }
    else if(result.status === 204) {
      message.error("Авторизация не была завершена. Повторите попытку")
    }
    else {
      if(result.status === 404) {
        message.error("Аккаунт не найден! Обновите страницу");
      }
      else if(result.status === 401) {
        message.error("Вы не запускали процесс авторизации авито аккаунта, либо он был прерван. Обновите страницу");
      }
      else {
        message.error("Что-то пошло не так! Подробности в консоли разработчка");
        console.log(result)
      }
    }
  }

  return (
    <div className={`mt-3`}>
      <Table columns={[
        { title: 'ID аккаунта', dataIndex: 'id', key: 'id' },

        { title: 'Пользователь', dataIndex: 'name', key: 'name' , render: (t: string, record: any) => (
          <span>
            <AvitoAccountInfoPopover name={t} client_number={record.client_number} phone={record.phone} email={record.email} />
          </span>
        ) },
        { 
          title: (
            <span>
              <ApiOutlined /> 
            </span>
          ),
           dataIndex: 'active',
            key: 'active', 
            render: (s: boolean) => (<div className={`w-[12px] h-[12px] ${!s ? 'bg-red-600' : 'bg-green-600'} rounded-full`}></div>) 
          },
        { title: 'Ссылка', dataIndex: 'profile_url', key: 'profile_url', render: (t: string) => (
          <Button href={t} target="_blank" type="link">
            Авито профиль
          </Button>
        )},
        { title: 'Дата добавления', dataIndex: 'created_at', key: 'created_at', render: (d: Date) => (
          <Popover
            content={
              <div>
                <p>{new Date(d).toLocaleString()}</p>
              </div>
            }
            title='Дата добавления'
            trigger="hover"
          >
            <Button>{new Date(d).toISOString().split('T')[0]}</Button>
          </Popover>
         

        ) },
          { title: 'Обработка запросов', dataIndex: 'process_webhooks', key: 'process_webhooks', render: (d: boolean, record: any) => (
            <Switch checked={d} onClick={(checked: boolean) => {webhooksProcessChange(record.id, checked)}} ></Switch>  
          )
        },
        { title: 'Действия', dataIndex: 'actions', key: 'actions' },
      ]} dataSource={accounts.map((a, i) => {
        return {
          ...a,
          key: i,
          actions: (
            
            <div className={`flex gap-2 items-center`}>
               <div>
            <SettingOutlined onClick={() => showPipelineModal(a)} />

            <Modal
              title="Настройка воронки"
              open={isPipelinesModalOpened}
              onCancel={handlePipelineModalCancel}
              onOk={handlePipelineModalOk}
            >
              <Select
                style={{ width: '100%', marginBottom: '10px' }}
                placeholder="Выберите воронку"
                value={selectedPipelineId || undefined}
                onChange={(value) =>  {
                  setSelectedPipelineStatusId(null)
                  setSelectedPipelineId(value || null)}
                }
              >
                {pipelines.map((pipeline) => (
                  <Select.Option key={pipeline.id} value={pipeline.id}>
                    {pipeline.name}
                  </Select.Option>
                ))}
              </Select>             
              <Select
                style={{ width: '100%' }}
                placeholder="Выберите статус воронки"
                value={selectedPipelineStatusId || undefined}
                onChange={(value) => setSelectedPipelineStatusId(value || null)}
              >
                {modalPipelineStatuses.map((status: { id: string | number; name: string }) => (
                    <Select.Option key={status.id} value={status.id}>
                      {status.name}
                    </Select.Option>
                  ))}
              </Select>
            
              {loadingSave && <p>Сохранение настроек...</p>}
            </Modal>
         </div>
              {a.active ? (
                <Popconfirm
                  title="Обновить аккаунт?"
                  description="Если аккаунт неактивен или возникли проблемы с доставкой, то обновите аккаунт"
                  onConfirm={() => refreshAccount(a.id)}
                  okText="Обновить"
                  cancelText="Отмена"
                >
                  <Button icon={<SyncOutlined />}></Button>
                </Popconfirm>
              ) : null}

              {a.active ? (
                <Popconfirm
                  title="Удалить аккаунт авито?"
                  description="После удаления аккаунта вы не сможете поддержать диалог с пользователями, которым отвечали с этого аккаунта"
                  onConfirm={() => onConfirm(a.id)}
                  okText="Удалить"
                  cancelText="Отмена"
                >
                  <Button danger style={{ width: 110 }}>Удалить</Button>
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="Включить аккаунт авито?"
                  description="При включении аккаунта мы проверим его на валидность и после включения Вы сможете получать сообщения"
                  onConfirm={() => onEnable(a.id)}
                  okText="Включить"
                  cancelText="Отмена"
                >
                  <Button style={{ width: 110 }}>Включить</Button>
                </Popconfirm>
              )}

              {a.active && !isLogin && location.hash.indexOf('_devmode') !== -1 ? (
                <Popconfirm
                  title={"Авторизовать авито аккаунт?"}
                  description={"После авторизации авито аккаунта будет доступна возможность загружать видео с amoCRM в авито чат\n\nВНИМАНИЕ!\nАвторизация авито аккаунта производится при помощи поддержки."}
                  onConfirm={() => loginAvitoAccount(a.id)}
                  okText={"Авторизовать"}
                  cancelText={"Отмена"}
                >
                  <Button>Login</Button>
                </Popconfirm>
              ) : ''}
            </div>
          )
        }
      })} />

      {loader && !isLogin ? (
          <div style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            background: 'rgba(0, 0, 0, .5)',
            top: '0',
            left: '0',
            zIndex: '500',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <PacmanLoader
              color={"#75a49a"}
              size={30}
            />
          </div>
        ) : null}
      {isLogin ? (
          <Modal title={"Ожидание..."} open={true} onOk={() => loginAvitoAccountSubmit(isLogin)} closable={false} footer={[
            <Button key={"back"} onClick={() => {
              setIsLogin(null)
            }}>
              Отмена
            </Button>,
            <Button key={"submit"} type={"primary"} onClick={() => loginAvitoAccountSubmit(isLogin)} loading={loader}>
              Подтвердить
            </Button>
          ]}>
            <p>Произведите вход в аккаунт авито.</p>
            <p>После успешного входа подтвердите действие, нажав "Подтвердить"</p>
          </Modal>
        ) : null}
    </div>
  )
});

export default AvitoAccountList;
