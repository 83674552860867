import { message } from "antd";
import api from "../../app/api";
import { avitoStore } from "../../app/stores";

const getTgAccounts = async (token: string) => {
  try {
    const accounts = await api.get(`tgbot/accounts`, {
      headers: { 'x-auth-token': token },
    });

    return accounts
  } catch (e) {
    message.error("Ошибка получения аккаунтов телеграм");
  }
  return;
};

export default getTgAccounts;
