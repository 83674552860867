import { User } from "../../types";
import api from ".";
import { message } from "antd";

const checkToken = async (token: string): Promise<User | boolean> => {
  return api
    .get(`/auth/checkToken?token=${token}`)
    .then((response) => {
      if (response.status < 400) {
        return response.data;
      } else {
        return message.error("Токен невалиден");
      }
    })
    .catch((error) => {
      return message.error("Токен невалиден");
    });
};

export default checkToken;
