import { Navigate, RouteObject } from "react-router-dom";
import { AvitoAccounts, Logs } from "../../pages";
import PagePayments from "../../pages/Payments";
import PageHooks from "../../pages/Hooks";

const prefix = `/app`;

const router: RouteObject[] = [
  { path: "/", element: <Navigate to={`/app`} /> },
  { path: `/${prefix}`, element: <AvitoAccounts title="Аккаунты авито" /> },
  { path: `/${prefix}/avito`, element: <AvitoAccounts title="Аккаунты авито" /> },
  { path: `/${prefix}/logs`, element: <Logs title="Логи"/> },
  { path: `/${prefix}/payments`, element: <PagePayments /> },
  { path: `/${prefix}/hooks`, element: <PageHooks /> },
];

export default router;
