import React from "react"
import { checkToken, getAvitoAccounts, MainLayout, PageTitle } from "../shared"
import { Button, Flex, Form, Input, message, Modal, Popconfirm, Spin, Table, Tag } from "antd"
import PaymentsEntity from "../entities/payments"
import { usePaymentsStore } from "../app/stores/payments.store"
import getPayments from "../shared/api/getPayments"
import { useAuthStore, useAvitoStore } from "../app/stores"
import { LoadingOutlined } from "@ant-design/icons"
import { observer } from "mobx-react"
import FormatPaymentReason from "../shared/modules/formatPaymentReason"
import Loading from "./Loading"
import textGenerateColor from "../shared/modules/textGenerateColor"
import { Link, useSearchParams } from "react-router-dom"
import postHook from "../shared/api/postHook"

const PageHooks = observer(() => {
    const [params] = useSearchParams();

    const { user, setUser, setIsAuth, isAuth } = useAuthStore();
    const { accounts, accountsLoaded } = useAvitoStore()

    React.useEffect(() => {
        if(!isAuth || !user) loadingToken()
        else {
            if(!accountsLoaded) {
                getAvitoAccounts({ token: user.token });
            }
        }
    }, [user, setUser, accounts])

    async function loadingToken() {
        const token = params.get("token");
        if(token) {
            const response = await checkToken(token);

            if(response && typeof response !== "boolean") {
                console.log(typeof response, response);
                setUser(response);
                setIsAuth(true);
            } else {
                message.error("Невалидный токен");
            }
        }
    }

    const [ deleteLoading, setDeleteLoading ] = React.useState(false)
    async function onDeleteHook(hookIndex: number) {
        if(!user)return
        if(!user?.messageHooks?.[hookIndex])return
        
        setDeleteLoading(true)

        const result = await postHook(user.messageHooks[hookIndex], user.token)
        if(!result)return message.error('Error #5123')

        setDeleteLoading(false)

        if(result.status === 200 && result.data === 'Hook was removed') {
            message.success(`Хук был успешно удален`)
            setUser({...user, messageHooks: [...user.messageHooks.filter(h => h !== user.messageHooks[hookIndex])]})
        }
        else if(result.status === 200 && result.data === 'Hook was added') {
            message.success(`Хук был успешно добавлен`)
            setUser({...user, messageHooks: [...user.messageHooks, user.messageHooks[hookIndex]]})

            setModalAdd(false)
        }
        else message.error('Error #5123.1')
    }

    const [ modalAdd, setModalAdd ] = React.useState(false)
    const [ modalAddForm, setModalAddForm ] = React.useState({
        hook: {
            value: '',
            help: '',
            status: '',
            onChange: (value: string) => {
                let help = ''
                let status = ''

                if(!value.length) {
                    help = 'Обязательное поле'
                    status = 'warning'
                }
                else {
                    if(!value.match(/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/)) {
                        help = 'Некорректная ссылка'
                        status = 'error'
                    }
                    else status = 'success'
                }

                setModalAddForm(old => {
                    return {...old, hook: {...old.hook, value, help, status}}
                })
            }
        }
    })
    const [ modalAddLoading, setModalAddLoading ] = React.useState(false)

    React.useEffect(() => {
        setModalAddForm(old => {
            return {...old, hook: {...old.hook, status: '', help: '', value: ''}}
        })
        setModalAddLoading(false)
    }, [modalAdd])

    async function onAddHook(hook: string, formStatus: string) {
        if(!hook?.length)return setModalAddForm(old => {
            return {...old, hook: {...old.hook, help: 'Обязательное поле', status: 'error'}}
        })
        if(formStatus !== 'success')return

        setModalAddLoading(true)

        const result = await postHook(hook, user.token)
        if(!result)return message.error('Error #5124')

        if(result.status === 200 && result.data === 'Hook was added') {
            message.success(`Хук был успешно добавлен`)
            setUser({...user, messageHooks: [...user.messageHooks, hook]})
        }
        else if(result.status === 200 && result.data === 'Hook was removed') {
            message.success(`Хук был успешно удален`)
            setUser({...user, messageHooks: [...user.messageHooks.filter(h => h !== hook)]})
        }
        else message.error('Error #5124.1')

        setModalAdd(false)
        setAddChattingHook(false)
    }

    const [ addChattingHook, setAddChattingHook ] = React.useState(false)

    if(!user?.messageHooks || !accounts || !Array.isArray(accounts))return (<Loading title={"Хуки"} />)
    return (
        <MainLayout title={"Хуки"}>
            <Modal open={addChattingHook} title={"Хук amoChatting"}
                footer={[
                    <Button shape={"round"} onClick={() => setAddChattingHook(false)} disabled={modalAddLoading}>Отмена</Button>,
                    <Button shape={"round"} type={"primary"} loading={modalAddLoading} onClick={() => onAddHook("https://amochatting.productlove.ru/microservices/hooks/amocrm/any/hook", 'success')}>Добавить</Button>
                ]}
                onCancel={() => !modalAddLoading && setAddChattingHook(false)}
            >
                <section>
                    <div className={`flex flex-col gap-2`}>
                        <span>Подключив хук amoChatting Вы сможете моментально получать сообщения отправленные из авито и из amoCRM в интеграцию amoChatting</span>
                        <span className={`text-gray-500`}>Если на Вашем портале amoCRM не установлена интеграция amoChatting, то Вы можете установить ее <Link to={"http://amocrm.ru/oauth/?state=state&mode=popup&client_id=c6b2f2f7-2bd2-498e-90b6-09244a2ad5c6"} target={"_blank"} className={`font-semibold text-blue-600`}>по ссылке</Link></span>
                    </div>
                </section>
            </Modal>
            <Modal open={modalAdd} title={"Добавление хука"}
                footer={[
                    <Button shape={"round"} onClick={() => setModalAdd(false)} disabled={modalAddLoading}>Отмена</Button>,
                    <Button shape={"round"} type={"primary"} loading={modalAddLoading} onClick={() => onAddHook(modalAddForm.hook.value, modalAddForm.hook.status)}>Добавить</Button>
                ]}
                onCancel={() => !modalAddLoading && setModalAdd(false)}
            >
                <section className={`mt-4`}>
                    <Form>
                        <Form.Item>
                            <Flex gap={32}>
                                <span className={`text-gray-500 w-[160px]`}>Права</span>
                                <Flex gap={4} vertical>
                                    <span>Входящие сообщения</span>
                                    <span>Исходящие сообщения</span>
                                </Flex>
                            </Flex>
                        </Form.Item>
                        <Form.Item>
                            <Flex gap={32}>
                                <span className={`text-gray-500 w-[160px]`}>Подключить аккаунты</span>
                                <Flex gap={4}>
                                    {accounts.map(acc => {
                                        return (<Tag className={`rounded-full px-4 py-1`}>{acc.name}</Tag>)
                                    })}
                                </Flex>
                            </Flex>
                        </Form.Item>
                        <Form.Item
                            help={modalAddForm.hook.help}
                            validateStatus={modalAddForm.hook.status as any}
                            hasFeedback
                        >
                            <Input placeholder={"Ссылка на сторонний сервис"} value={modalAddForm.hook.value} onChange={e => modalAddForm.hook.onChange(e.target.value)} />
                        </Form.Item>
                    </Form>
                </section>
            </Modal>

            <PageTitle>Хуки</PageTitle>
            <section>
                <div>
                    <span className={`text-gray-500`}>Используйте хуки, чтобы моментально уведомлять сторонние сервисы о событиях, совершенных на Ваших аккаунтах</span>
                </div>
                <div className={`mt-4`}>
                    <Flex justify={"right"} gap={8}>
                        {user.messageHooks.join(' ').indexOf('microservices/hooks/amocrm/any/hook') === -1 ? (
                            <Button size={"large"} type={"primary"} shape={"round"} onClick={() => setAddChattingHook(true)}>Установить хук amoChatting</Button>
                        ) : null}
                        <Button size={"large"} shape={"round"} onClick={() => setModalAdd(true)}>Добавить хук</Button>
                    </Flex>
                </div>
                <div className={`mt-2`}>
                    <Table
                        columns={[
                            { key: 'hook', dataIndex: 'hook', title: 'Хук', render: (value) => {
                                if(value.indexOf('microservices/hooks/amocrm/any/hook') !== -1)return (
                                    <Tag className={`rounded-full px-4 py-1 text-base font-semibold`} color={"#7743db"}>amoChatting</Tag>
                                )
                                return value
                            } },
                            { key: 'rights', dataIndex: 'rights', title: 'Выданные права' },
                            { key: 'accounts', dataIndex: 'accounts', title: 'Подключенные аккаунты' },
                            { key: 'actions', dataIndex: 'actions' },
                        ]}
                        dataSource={user.messageHooks.map((hook, i) => {
                            return {
                                key: hook,
                                hook,
                                rights: (
                                    <Flex gap={4} vertical>
                                        <span>Входящие сообщения</span>
                                        <span>Исходящие сообщения</span>
                                    </Flex>
                                ),
                                accounts: (
                                    <Flex gap={4}>
                                        {accounts.map(acc => {
                                            return (<Tag className={`rounded-full px-4 py-1`}>{acc.name}</Tag>)
                                        })}
                                    </Flex>
                                ),
                                actions: (
                                    <Flex gap={4} justify={"right"}>
                                        <Popconfirm
                                            title={"Удаление хука"}
                                            description={"Подтвердите удаление данного хука"}
                                            onConfirm={() => onDeleteHook(i)}
                                            okText={"Удалить"}
                                            cancelText={"Отмена"}
                                            okButtonProps={{
                                                shape: "round",
                                                danger: true,
                                                loading: deleteLoading
                                            }}
                                            cancelButtonProps={{
                                                shape: "round",
                                                disabled: deleteLoading
                                            }}
                                            open={deleteLoading === true ? true : undefined}
                                        >
                                            <Button shape={"round"} danger type={"primary"} disabled={deleteLoading} loading={deleteLoading}>Удалить</Button>
                                        </Popconfirm>
                                    </Flex>
                                )
                            }
                        })}
                    />
                </div>
            </section>
        </MainLayout>
    )
})

export default PageHooks
