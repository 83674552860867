import { observer } from "mobx-react";
import { FC } from "react";
import styled from "styled-components";
import { useAuthStore } from "../app/stores";
import { Link } from "react-router-dom";
import { Button, Flex } from "antd";

const Domain = styled.p`
  font-size: 0.9em;
  margin: 0;
  padding: 0;
  color: #888;
`;

const SidebarContainer = styled.div`
  min-width: 280px;
  max-width: 280px;
  height: 100vh;
  position: sticky;
  top: 0px;
  border-right: 1px solid #eee;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
  z-index: 1;
  background: white;
  h1 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 1.3em;
    color: #242938;
  }

  @media (max-width: 1100px) {
    flex-direction: row;
    height: 60px;
    justify-content: space-between;
    min-width: none;
    max-width: none;
    border-bottom: 1px solid #eee;
    align-items: center;
    border-right: none;
    h1 {
      display: none;
    }
    ${Domain} {
      display: none;
    }
    a {
    }
  }
`;

const Footer = styled.div`
  margin-top: auto;

  p {
    font-size: 0.9em;
  }

  a {
    text-decoration: none;
    color: #4688eb;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 1100px) {
    margin: 0;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
    color: #242938;
    border-bottom: 1px solid #eee;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 0.95em;
    transition: border-bottom 0.2s;
    &:hover {
      border-bottom: 1px solid #93b8fc;
    }
  }
  @media (max-width: 1100px) {
    flex-direction: row;
    gap: 10px;
    a {
      border-bottom: none;
    }
  }
`;

const Sidebar: FC = observer(() => {
  const { user } = useAuthStore();

  return (
    <SidebarContainer>
      <h1>Avito x amoCRM</h1>
      <Domain>{user.referer}</Domain>
      <Domain>Баланс: {user.balance} руб.</Domain>
      <Buttons>
        <Link to={`/app/avito?token=${user.token}`}>Авито аккаунты</Link>
        <Link to={`/app/logs?token=${user.token}`}>Логи</Link>
        <Link to={`/app/payments?token=${user.token}`}>История баланса</Link>
        <Link to={`/app/hooks?token=${user.token}`}>Хуки</Link>
      </Buttons>
      <Flex vertical className={`mt-8`}>
        <div>
          <div className={`border rounded-3xl p-6 px-4 border-gray-200`}>
            <Flex vertical gap={4}>
              <span className={`text-xl font-semibold text-center`}>
                Общайтесь <span className={`text-blue-400`}>удобнее</span> с amoChatting
              </span>
              <span className={`text-center text-gray-500 font-medium`}>Все чаты amoCRM в стабильном, стильном и удобном мессенджере</span>
              <Link to={"https://www.amocrm.ru/oauth/?state=state&mode=popup&client_id=c6b2f2f7-2bd2-498e-90b6-09244a2ad5c6"} target={"_blank"} className={`w-full`}>
                <div className={`p-[3px] rounded-full mt-4 w-full overflow-hidden`}
                  style={{
                      background: ' linear-gradient(135deg, #1E90FF, #4a6cf7)'
                  }}
                >
                  <div className={`flex justify-center items-center bg-white w-full h-full rounded-full p-2 py-4`}>
                    <span className={`text-center uppercase font-semibold text-[#4a6cf7]`}>Установить</span>
                  </div>
                </div>
              </Link>
            </Flex>
          </div>
        </div>
      </Flex>
      <Footer>
        <p>
          Поддержка:{" "}
          <a
            href={`https://t.me/avitoamohelpbot?start=webpanel-${user.domain}`}
            target="_blank"
            rel="noreferrer"
          >
            Telegram
          </a>
        </p>
      </Footer>
    </SidebarContainer>
  );
});

export default Sidebar;
