import { message } from "antd";
import api from "../../app/api";
import { paymentsStore } from "../../app/stores/payments.store";

const getPayments = async (token: string) => {
  try {
    const list = await api.get(`amo/${token}/payments`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    paymentsStore.setList(list?.data || [])
  } catch (e) {
    message.error("Ошибка получения аккаунтов");
  }
  return;
};

export default getPayments;
