import { Button, Flex, message, Modal, QRCode, Spin } from "antd";
import React, { FC } from "react";
import Gateway from "./modules/Gateway";
import { useAuthStore } from "../app/stores";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

const BindTelegramAccountModal: FC<{
    onClose?: () => void
    show: boolean
}> = observer(({ onClose, show }) => {
    const { tgRegLink } = useAuthStore()
    const [ qrStatus, setQrStatus ] = React.useState<"active" | "loading" | "expired" | undefined>('active')

    React.useEffect(() => {
        Gateway.on<any, any>('tgbot', 'reg:result', result => {
            if(result.statusCode === 102) {
                return setQrStatus('loading')
            }

            if(result.statusCode === 404) {
                return window.location.href = '/'
            }
            if(result.statusCode === 409) {
                setTimeout(() => {
                    window.location.reload()
                }, 1000)

                return message.error(`Данный телеграм аккаунт уже привязан к этому порталу`)
            }
            
            if(result.statusCode === 200) {
                message.success(`Телеграм аккаунт был успешно привязан`)
                onClose?.()

                setTimeout(() => {
                    window.location.reload()
                }, 1000)
            }
            else {
                message.error(`Ошибка. Подробности в консоли`)
                console.log(result)
            }
        })
    }, [])

    if(!show)return null
    return (
        <Modal open centered title={"Привязка телеграм аккаунта"}
            onCancel={() => onClose?.()}
            footer={[
                <Button shape={"round"} type={"default"} onClick={() => onClose?.()}>Закрыть</Button>
            ]}
        >
            <Flex vertical>
                <span className={`text-xs`}>Подключите неограниченное число телеграм аккаунтов к вашему порталу</span>
                <span className={`text-xs`}>Подключив аккаунт вы сможете получать уведомления обо всем, что происходит на вашем портале</span>

                <span className={`mt-4 block text-center`}>Для привязки воспользуйтесь камерой телефона или перейдите по ссылке</span>
            </Flex>
            <Flex justify={"center"} vertical align={"center"} gap={10} style={{ marginTop: "14px" }}>
                {tgRegLink ? (
                    <>
                        <QRCode value={tgRegLink} size={200} status={qrStatus} />
                        <Link to={tgRegLink} target={"_blank"}>
                            <Button type={"text"}>перейти по ссылке</Button>
                        </Link>
                    </>
                ) : (
                    <div className={`flex flex-col gap-2`}>
                        <span>Загрузка QR кода...</span>
                        <Spin indicator={<LoadingOutlined spin />} size="large" />
                    </div>
                )}
            </Flex>
        </Modal>
    )
})

export default BindTelegramAccountModal