import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Empty, Flex, message, Modal, Popconfirm, Spin } from "antd";
import React, { FC } from "react";
import BindTelegramAccountModal from "../shared/bindTelegramAccount";
import getTgAccounts from "../shared/api/getTgAccounts";
import { useAuthStore } from "../app/stores";
import TelegramAccount from "../types/telegram.account";
import Gateway from "../shared/modules/Gateway";
import deleteTgAccount from "../shared/api/deleteTgAccount";
import { observer } from "mobx-react";
import { useTgAccountsStore } from "../app/stores/tgAccounts.store";


const WidgetTelegramAccounts: FC<{
    opened: boolean
    tgAccountsModalFirst: boolean
    onClose?: () => void
}> = observer(({ opened, onClose, tgAccountsModalFirst }) => {
    const { user, setUser } = useAuthStore()
    const { tgAccounts, setTgAccounts } = useTgAccountsStore()

    const [ bindTgModal, setBindTgModal ] = React.useState(false)

    React.useEffect(() => {
        if(!tgAccounts && user?.token) {
            getAccounts()
        }
    }, [tgAccounts, user])
    React.useEffect(() => {
        if(tgAccountsModalFirst) setBindTgModal(true)
    }, [tgAccountsModalFirst])

    async function getAccounts() {
        const result = await getTgAccounts(user.token)
        if(!result)return
        
        setTgAccounts(result.data)
    }

    if(!tgAccounts && opened)return (
        <Modal open centered title={"Загрузка"}
            onCancel={() => onClose?.()}
        >
            <Flex justify={"center"}>
                <Spin indicator={<LoadingOutlined spin />} size="large" />
            </Flex>
        </Modal>
    )
    if(!tgAccounts)return (<></>)
    return (
        <>
            <Modal open={opened} title={"Телеграм аккаунты"} centered
                footer={[
                    <Button type={"text"} shape={"round"} onClick={() => onClose?.()}>Закрыть</Button>,
                    <Button type={"primary"} shape={"round"} onClick={() => setBindTgModal(true)}>Подключить аккаунт</Button>
                ]}
                onCancel={() => onClose?.()}
            >
                <div>
                    <section className={`flex flex-col`}>
                        <span className={`text-xs`}>Подключите неограниченное число телеграм аккаунтов к вашему порталу</span>
                        <span className={`text-xs`}>Подключив аккаунт вы сможете получать уведомления обо всем, что происходит на вашем портале</span>
                    </section>
                    <section className={`mt-4 border-t-2 border-t-gray-100`}>
                        <div className={`mt-4`}>
                            <span>Сейчас подключено: <span>{tgAccounts.length} аккаунта</span></span>
                        </div>
                        <div className={`mt-2 w-full rounded-md p-4 max-h-[600px] overflow-auto`} style={{
                            boxShadow: 'inset 0 0px 4px 2px rgb(0 0 0 / 0.08)'
                        }}>
                            <div className={`flex flex-col gap-3`}>
                                {tgAccounts.map((a, i) => {
                                    return (<AccountBlock key={i} telegram_id={a.telegram_id} name={a.first_name + ' ' + (a.last_name || '')} username={a.username} created_at={a.created_at} />)
                                })}
                                {!tgAccounts?.length ? (
                                    <Empty description={"Аккаунтов еще не подключено"} />
                                ) : null}
                            </div>
                        </div>
                    </section>
                </div>
            </Modal>
            <BindTelegramAccountModal onClose={() => {
                setBindTgModal(false)
                if(tgAccountsModalFirst) onClose?.()
            }} show={bindTgModal} />
        </>
    )
})

export default WidgetTelegramAccounts

interface IAccountBlock {
    telegram_id: string
    name: string
    username: string
    created_at: Date
}
function AccountBlock({
    telegram_id,
    name,
    username,
    created_at
}: IAccountBlock) {
    const { user, setUser } = useAuthStore()
    const { tgAccounts, setTgAccounts } = useTgAccountsStore()

    const [ deleteLoading, setDeleteLoading ] = React.useState(false)
    async function onDeleteAccount(telegram_id: string) {
        if(!tgAccounts)return
        setDeleteLoading(true)

        const result = await deleteTgAccount(user.token, telegram_id)
        if(!result)return
        
        setTgAccounts([...tgAccounts.filter((a: TelegramAccount) => a.telegram_id !== telegram_id)])
        setDeleteLoading(false)

        message.success(`Телеграм аккаунт @${username} был успешно удален`)
    }

    return (
        <div className={`flex items-center justify-between gap-2`}>
            <div className={`flex flex-col gap-1`}>
                <div className={`flex items-center gap-1`}>
                    <span>{name}</span>
                    <a href={`https://t.me/${username}`} className={`text-xs`} target={"_blank"}>@{username}</a>
                </div>
                <span className={`text-xs text-gray-500`}>Подключен {new Date(created_at).toLocaleString()}</span>
            </div>
            <div className={`flex items-center gap-2`}>
                <Popconfirm
                  title="Удалить привязку?"
                  description="При привязки на данный телеграм аккаунт более не будут приходить уведомления"
                  onConfirm={() => onDeleteAccount(telegram_id)}
                  okText="Удалить"
                  cancelText="Отмена"
                >
                  <Button loading={deleteLoading} danger icon={(<DeleteOutlined />)}></Button>
                </Popconfirm>
            </div>
        </div>
    )
}