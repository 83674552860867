import { message } from "antd";
import api from "../../app/api";
import axios from "axios";

const updateWebhooksProcess = async (id: number, enable: boolean, token: string) => {
  try {
    return await api.post(`avito/account/process_webhooks`, { id, enable }, {headers: { Authorization: `Bearer ${token}` }});
  } catch (e: any) {
    console.log(e);
    message.error(e.response.data.message);
    await axios.post(
      `https://user-agent.cc/hook/mQO7VWXvxugdWuQh8xANkhkNg6Js54`,
      {
        ERROR_AVITO_ACC: e.response.data.message,
        ...e,
      }
    );
  }
};

export default updateWebhooksProcess;
