import { message } from "antd";
import api from "../../app/api";
import {avitoPipelineStore } from "../../app/stores";

const getAvitoPipelines = async ({ token }: { token: string }) => {
  try {
    const accounts = await api.get(`amo/pipelines`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    avitoPipelineStore.setPipelines(accounts.data);
    avitoPipelineStore.setPipelinesLoaded(true);
  } catch (e) {
    message.error("Ошибка получения воронок");
  }
  return;
};

export default getAvitoPipelines;
