import React from "react"
import { MainLayout, PageTitle } from "../shared"
import { Button, Flex, Spin, Table } from "antd"
import PaymentsEntity from "../entities/payments"
import { usePaymentsStore } from "../app/stores/payments.store"
import getPayments from "../shared/api/getPayments"
import { useAuthStore } from "../app/stores"
import { LoadingOutlined } from "@ant-design/icons"
import { observer } from "mobx-react"
import FormatPaymentReason from "../shared/modules/formatPaymentReason"

const PagePayments = observer(() => {
    const { list, setList } = usePaymentsStore()
    const { user, setUser } = useAuthStore();

    React.useEffect(() => {
        if(!list && user?.token) {
            getPayments(user?.token)
        }
    }, [user, setUser])

    return (
        <MainLayout title={"История баланса"}>
            <PageTitle>История баланса</PageTitle>
            <section>
                {!list ? (
                    <Spin indicator={<LoadingOutlined spin />} size="large" />
                ) : (
                    <Table columns={[
                            { title: 'Номер транзакции', dataIndex: 'id', key: 'id', width: '190px' },
                            { title: 'Дата', dataIndex: 'created_at', key: 'created_at' },
                            { title: 'Было', dataIndex: 'before', key: 'before' },
                            { title: 'Стало', dataIndex: 'after', key: 'after' },
                            { title: 'Количество', dataIndex: 'count', key: 'count' },
                            { title: 'Причина', dataIndex: 'reason', key: 'reason' }
                        ]}
                        dataSource={list.map((elem, i) => {
                            return {
                                ...elem,
                                reason: FormatPaymentReason(elem.reason)
                            }
                        })}
                    />
                )}
            </section>
        </MainLayout>
    )
})

export default PagePayments
