/**
 * A React component that renders a popover with user account information.
 *
 * @param client_number - The client number of the user.
 * @param name - The name of the user.
 * @param phone - The phone number of the user.
 * @param email - The email address of the user.
 * @returns A React component that displays a popover with the user's account information.
 */
import { observer } from "mobx-react";
import { Button, Popover } from 'antd';
import { FC, useState } from "react";

interface AvitoAccountInfoPopoverProps {
  client_number: number;
  name: string;
  phone: string;
  email: string;

}

const AvitoAccountInfoPopover: FC<AvitoAccountInfoPopoverProps> = observer(({ client_number, name, phone, email }) => {
  return (
    <Popover
      content={
        <div>
          <p><b>Номер аккаунта:</b> {client_number}</p>
          <p><b>Имя пользователя:</b> {name}</p>
          <p><b>Телефон:</b> {phone}</p>
          <p><b>Почта:</b> {email}</p>
        </div>
      }
      title='Информация о пользователе'
      trigger="hover"
    >
      <Button>{name}</Button>
    </Popover>
  );
});

export default AvitoAccountInfoPopover;