import { message } from "antd";
import api from "../../app/api";
import { avitoStore } from "../../app/stores";

const deleteTgAccount = async (token: string, telegram_id: string) => {
  try {
    const accounts = await api.post(`tgbot/account/delete`, { telegram_id }, {
        headers: { 'x-auth-token': token }
    });

    return true
  } catch (e) {
    message.error("Ошибка удаления телеграм аккаунта");
  }
  return;
};

export default deleteTgAccount;
