import { action, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";
import TelegramAccount from "../../types/telegram.account";

class TgAccountsStore {
  tgAccounts: TelegramAccount[] | null = null

  constructor() {
    makeObservable(this, {
        tgAccounts: observable,
        setTgAccounts: action.bound
    });
  }

  setTgAccounts(list: TelegramAccount[]) {
    this.tgAccounts = list;
  }
}

export const tgAccountsStore = new TgAccountsStore();
export const TgAccountsContext = createContext(tgAccountsStore);
export const useTgAccountsStore = () => useContext(TgAccountsContext);
