import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { mainRouter } from "./app/routers";
import "./app/style.css";
import { Provider } from "mobx-react";
import { authStore, avitoStore, logsStore, useAuthStore } from "./app/stores";
import "./index.css"
import React from "react";
import Gateway from "./shared/modules/Gateway";
import { message } from "antd";

const router = createBrowserRouter([...mainRouter]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Index />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function Index() {
  return (
    <Provider authStore={authStore} avitoStore={avitoStore} logsStore={logsStore}>
      <RouterProvider router={router} />
    </Provider>
  )
}